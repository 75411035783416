var heroSlider = new Swiper('.heroSlider', {
    disableOnInteraction: false,
    loop: true,
    speed: 1000,
    effect: 'fade',
    autoplay: {
        delay: 5000
    },
    fadeEffect: {
        crossFade: true
    },
    navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
    }
});


jQuery(document).ready(function($) {

    $.fn.extend({
        animateCss: function(animationName, callback) {
            var animationEnd = (function(el) {
                var animations = {
                    animation: 'animationend',
                    OAnimation: 'oAnimationEnd',
                    MozAnimation: 'mozAnimationEnd',
                    WebkitAnimation: 'webkitAnimationEnd',
                };

                for (var t in animations) {
                    if (el.style[t] !== undefined) {
                        return animations[t];
                    }
                }
            })(document.createElement('div'));

            this.addClass('animated ' + animationName).one(animationEnd, function() {
                $(this).removeClass('animated ' + animationName);

                if (typeof callback === 'function') callback();
            });

            return this;
        },
    });


    $("[data-scroll]").each(function(index, el) {
        var $elm = $(this);
        var $offset =  $elm.data("anim-offset");
        $elmWatcher = scrollMonitor.create($elm, $offset);

        $elmWatcher.enterViewport(function() {
            $elm.addClass('animActive');
            $elm.find("[data-anim]").each(function() {
                $(this).css("animation-delay",+$(this).data("anim-delay")+"s" ).addClass("animated "+ $(this).data("anim")).css("visibility","visible");
                //console.log($(this).attr("class"));
            });
        });
    });



if($('.headermob').length !== 0){
    var slideout = new Slideout({
        'panel': document.getElementById('panel'),
        'menu': document.getElementById('menumob'),
        'padding': 256,
        'tolerance': 70
    });


    var fixed = $('.headermob, .strans,.overview-slider');

    slideout.on('translate', function(translated) {
        fixed.css("transform",'translateX(' + translated + 'px)');
    });

    slideout.on('beforeopen', function() {
        fixed.css("transition",'transform 300ms ease');
        fixed.css("transform",'translateX(256px)');

        $('.hamburger').addClass('h-active');
        $('.button-reserve-fixed').css("opacity","0");
    });

    slideout.on('beforeclose', function() {
        fixed.css("transition",'transform 300ms ease');
        fixed.css("transform",'translateX(0px)');

        $('.hamburger').removeClass('h-active');
        $('.button-reserve-fixed').css("opacity","1");
    });

    slideout.on('open', function() {
        fixed.css("transition",'');
    });

    slideout.on('close', function() {
        fixed.css("transition",'');
    });
}



    // makes the parallax elements
    function parallaxIt() {
        // create variables
        var $fwindow = $(window);
        var scrollTop = window.pageYOffset || document.documentElement.scrollTop;

        var $contents = [];
        var $backgrounds = [];

        // for each of content parallax element
        $('[data-type="content"]').each(function(index, e) {
            var $contentObj = $(this);

            $contentObj.__speed = ($contentObj.data('speed') || 1);
            $contentObj.__fgOffset = ($contentObj.data('offset') || $contentObj.offset().top);
            $contents.push($contentObj);
        });

        // for each of background parallax element
        $('[data-type="background"]').each(function() {
            var $backgroundObj = $(this);

            $backgroundObj.__speed = ($backgroundObj.data('speed') || 1);
            $backgroundObj.__fgOffset = ($contentObj.data('offset') || $contentObj.offset().top);
            $backgrounds.push($backgroundObj);
        });

        // update positions
        $fwindow.on('scroll resize', function() {
            scrollTop = window.pageYOffset || document.documentElement.scrollTop;

            $contents.forEach(function($contentObj) {
                var yPos = $contentObj.__fgOffset - scrollTop / $contentObj.__speed;

                $contentObj.css('transform', "translateY(" + -yPos + "px)");
                //console.log("translateY("+ yPos+"px)");
            })

            $backgrounds.forEach(function($backgroundObj) {
                var yPos = -((scrollTop - $backgroundObj.__fgOffset) / $backgroundObj.__speed);

                $backgroundObj.css({
                    backgroundPosition: '50% ' + yPos + 'px'
                });
            });
        });

        // triggers winodw scroll for refresh
        $fwindow.trigger('scroll');
    };

    parallaxIt();




    var cl = $(".main-nav .nav").children().not(".logo").clone(true)
    $('#menumob').prepend(cl).wrapInner('<ul></ul>');

    // mobile menu
    $('.hamburger').click(function(event) {
        $('.left-enq-form').toggleClass('d-none');
        $(this).toggleClass('h-active');
        slideout.toggle();
    });



    $(function() {
        $(window).scroll(function() {
            if ($(this).scrollTop() > 2500) {
                $('.gotop').fadeIn();
            } else {
                $('.gotop').fadeOut();
            }
        });

        // scroll body to 0px on click
        $('.gotop').click(function() {
            $('body,html').animate({
                scrollTop: 0
            }, 800);
            return false;
        });
    });


});


function innerpagefunc() {
    if ($(window).width()>1199) 
    {
        var bodyheight = $(window).height();
        if(bodyheight < 520)
        {
            $('body.inner-page').height(520);  
            $('body.inner-page').css("overflow","scroll"); 
            $('body.inner-page').css("overflow-x","hidden");   
            $('.page-content-section').css("padding-bottom","80px")
            function mainheader() {
                var $winPos = $(window).scrollTop();
                var $header = $(".header-main");
                if ($winPos > 0) {
                    $header.addClass('header-active');
                } else {
                    $header.removeClass('header-active');
                }
            }
            mainheader();

            // fixed header
            $(window).scroll(function(event) {
                mainheader();
            });
        }
        else
        {
            var headerheight = $('.header-main').height();
            var footerheight = $('footer').height();
            var bodyminusheader = bodyheight - headerheight;
            var totalh = bodyminusheader - footerheight;
            $('.scroll-content').height(totalh - 100);

            $(".scroll-content").mCustomScrollbar({
                alwaysShowScrollbar: true,
                theme: "theme"
            });
        }
    }
}

innerpagefunc();

$(window).resize(function() {
    innerpagefunc();
})

$( document ).ready(function() {
    $('.inner-page').css("opacity","1")
});

$(window).on('load', function() {
    $('.left-select').trigger('change');
});

function bannerfunc() {
    $('.slider-js').width($(window).width())
    $('.slider-js').height($(window).height())
    /*console.log($(window).width());
    console.log($(window).height());*/
}
bannerfunc()
$(window).resize(bannerfunc);